import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "MVP | C@iA.parts ";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 4002,
    Michelin: 4003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.com/fr/accueil.html",
    eurorepar: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://mvp-cloudio.ia.parts/index.php/settings/admin/serverinfo",
    diag: "https://run.ia.parts",
    erp: "https://mvp-cloudio.ia.parts/",
    store: "https://mvp-cloudio.ia.parts/index.php/settings/apps",
    visio: "https://meetbox.ia.parts",
    com: "https://mvp-cloudio.ia.parts/index.php/apps/announcementcenter/",
    help: "https://mvp-cloudio.ia.parts/index.php/apps/circles/",
    tecrmi: "https://oem1.ia.parts",
};

export const TIRE_APPS = [
    {
        name: "07ZR",
        url:
            process.env.REACT_APP_PROXY_SERVER_HOST +
            ":" +
            PROXY_PORT["07ZR"] +
            "/fr/connexion/",
    },
    {
        name: "Michelin",
        url:
            process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Michelin,
    },
    {
        name: "Hankook",
        url: "https://www.hankooktire.com/fr/passenger-cars.html",
    },
    {
        name: "Pirelli",
        url: "https://www.pirelli.com/tyres/fr-fr/car/homepage",
    },
    {
        name: "Bridgestone",
        url: "https://www.bridgestone.fr/pneus-tourisme/",
    },
];

export const iframePartSelector = {
    fr: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
    en: "https://mvp-cloudio.ia.parts/index.php/apps/analytics/",
};

export const iframeCarSelector = {
    fr: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
    en: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
    es: "https://mvp.ia.parts/v2/CarSelector/?mode=tablette",
};

export const iframeContactSelector = {
    fr: "https://mvp-cloudio.ia.parts/",
    en: "https://mvp-cloudio.ia.parts/",
    es: "https://mvp-cloudio.ia.parts/",
};

export const DIAG_CAR_MANUFACTURERS = [
    {
        name: "Peugeot",
        url: "http://public.servicebox.peugeot.com/pages/index.jsp",
    },
    { name: "Citroën", url: "http://service.citroen.com/pages/index.jsp" },
    { name: "DS", url: "https://run.ia.parts" },
    { name: "BMW", url: "https://oss.bmw.de/" },
    { name: "Dacia", url: "http://www.infotech.renault.com/fo/accueil.action" },
    { name: "Opel", url: "https://www.gme-infotech.com/users/login.html" },
    { name: "Vauxhall", url: "https://run.ia.parts" },
    {
        name: "Ford",
        url: `${process.env.REACT_APP_PROXY_SERVER_HOST}:${PROXY_PORT.Ford}/home/loginForm.do`,
    },
    {
        name: "Volkswagen",
        url: "https://erwin.volkswagen.de/erwin/showHome.do",
    },
    {
        name: "Renault",
        url: "http://www.infotech.renault.com/fo/accueil.action",
    },
    { name: "Seat", url: "https://run.ia.parts" },
    { name: "Fiat", url: "https://run.ia.parts" },
    { name: "Mercedes", url: "https://run.ia.parts" },
    { name: "Mitsubishi", url: "https://run.ia.parts" },
    { name: "Skoda", url: "https://run.ia.parts" },
    { name: "Kia", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const DIAG_TOOLS_MANUFACTURERS = [
    { name: "Actia", img: `${apiRootURL}/media/dock/actia.png` },
    { name: "Bosch", img: `${apiRootURL}/media/logos/BOSCH.png` },
    { name: "C@iA", img: "https://medias2.ia.parts/pneus/app/c@ia.png" },
    { name: "C@iA", img: "https://medias2.ia.parts/pneus/app/c@ia.png" },
];

export const CAR_SELECTOR_APPS = [
    {
        name: "RRG",
        url: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.RRG,
        img: `${apiRootURL}/media/logos/RRG.png`,
    },
    {
        name: "VW",
        url: "https://www.volkswagen.fr/app/stocks/",
        img: `${apiRootURL}/media/logos/vw.png`,
    },
    {
        name: "LARGUS",
        url: "https://occasion.largus.fr/",
        img: `${apiRootURL}/media/logos/largus.jpg`,
        openInNewTab: true,
    },
    {
        name: "Lacentrale",
        url: "https://www.blablacar.fr/",
        img: `${apiRootURL}/media/logos/lacentrale.png`,
    },
    /*{ name: 'LeBonCoin', url: 'https://www.leboncoin.fr/recherche/?category=2', img: `${apiRootURL}/media/logos/C@IA.png` },*/
    {
        name: "HERTZ",
        url: "https://www.hertz.fr/rentacar/reservation/",
        img: `${apiRootURL}/media/logos/hertz.png`,
    },
];

export const PART_SELECTOR_APPS = [
    {
        name: "atelio",
        url: "http://www.atelio-chiffrage.com",
        img: `${apiRootURL}/media/logos/atelio.png`,
    },
    {
        name: "autossimo",
        url: "http://www.autossimo.com/extranetv3/accueil/index.php",
        img: `${apiRootURL}/media/logos/autossimo.jpg`,
    },
    {
        name: "Motor Club",
        url: "https://www.motors-club.com/form/logon.php",
        img: `${apiRootURL}/media/logos/metb.gif`,
    },
    {
        name: "RAM",
        url: "https://www.portaildigital.parts.renault.com",
        img: `${apiRootURL}/media/logos/ram.png`,
    },
    {
        name: "PA",
        url: "http://reparo.ia.parts",
        img: `${apiRootURL}/media/logos/pa.png`,
    },
    {
        name: "oscaro",
        url: "https://www.oscaro.com",
        img: `${apiRootURL}/media/logos/oscaro2.jpg`,
    },
    {
        name: "omniparts",
        url: "http://omniparts.com.co/",
        img: `${apiRootURL}/media/logos/op.png`,
    },
    {
        name: "Partslink24",
        url: "https://www.partslink24.com/",
        img: `${apiRootURL}/media/logos/pl24.jpg`,
    },
    {
        name: "G-PRE",
        url: "http://www.globalpre-chiffrage.fr/index.html",
        img: `${apiRootURL}/media/logos/gpre.png`,
    },
    {
        name: "D2A",
        url: "https://intranet.d2alliance.fr/?p=home",
        img: `${apiRootURL}/media/logos/d2a.png`,
    },
];

// ContactSelector (Page /social)
export const CONTACT_SELECTOR_PARTNERS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-qhg-gtw",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-evh-366",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 3,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 4,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 5,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 6,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 7,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 8,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 9,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];
export const CONTACT_SELECTOR_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-qhg-gtw",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-evh-366",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "Slack C@iA",
        url: "https://slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 3,
        name: "Slack Equanim-IT",
        url: "https://equanim-it.slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 4,
        name: "Slack Nexus",
        url: "https://athena.slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 5,
        name: "Slack Scoria",
        url: "https://scoriaa.slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 6,
        name: "Slack MarketParts",
        url: "https://marketpart.slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 7,
        name: "Slack FNB",
        url: "https://fnb.slack.ia.run",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 8,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 9,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];
export const CONTACT_SELECTOR_CUSTOMER_RELATIONS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-qhg-gtw",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://experts.ia.run/C@iA/phi-evh-366",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 3,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 4,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];
export const CONTACT_SELECTOR_SOCIAL_NETWORK = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const CO2_STEPS = [
    { index: 100, label: "A" },
    { index: 120, label: "B" },
    { index: 140, label: "C" },
    { index: 160, label: "D" },
    { index: 200, label: "E" },
    { index: 250, label: "F" },
    { index: Infinity, label: "G" },
];

export const EURO_NORMS = [
    { date: "01/09/2017", label: "Euro 6c" },
    { date: "01/09/2015", label: "Euro 6b" },
    { date: "01/01/2011", label: "Euro 5" },
    { date: "01/01/2006", label: "Euro 4" },
    { date: "01/01/2001", label: "Euro 3" },
    { date: "01/01/1996", label: "Euro 2" },
    { date: "01/01/1993", label: "Euro 1" },
];

export const CRITAIR_TYPE_ELECTRIC = ["ELECTRIC"];
export const CRITAIR_TYPE_GAZ_HYBRID = [
    "GAZ NAT.VEH",
    "G.P.L.",
    "CARB GAZEUX",
    "GAZOGENE",
];
export const CRITAIR_TYPE_ESSENCE = ["ESSENCE"];
export const CRITAIR_TYPE_GAZOLE = ["GAZOLE", "GAZ+ELEC HNR", "GAZOLE+GAZO"];

export const CRITAIR_ESSENCE_STEPS = [
    { date: "01/01/2011", value: 1 },
    { date: "01/01/2006", value: 2 },
    { date: "01/01/1997", value: 3 },
    { date: "01/01/1400", value: 6 },
];

export const CRITAIR_GAZOLE_STEPS = [
    { date: "01/01/2011", value: 2 },
    { date: "01/01/2006", value: 3 },
    { date: "01/01/2001", value: 4 },
    { date: "01/01/1997", value: 5 },
    { date: "01/01/1400", value: 6 },
];

export const WORDPRESS_URL = "https://wp.n.parts/mvp";
type RseSlide = { iframeURL?: string; imgSrc: string };

const WP_CONTENT_PATH = "https://wp.n.parts/mvp/wp-content/uploads";
export const RSE_SLIDES: RseSlide[] = [
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=ouverture-de-pap-gennevilliers-le-samedi-matin",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/ouverture-pap-gene.png`,
    },
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=nouveau-psd-skf-en-stock-sur-la-plateforme-de-fresnes",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/Nouveau-PSD-SKF-en-stock-sur-la-Plateforme-de-Fresnes.png`,
    },
    {
        iframeURL:
            "https://wp.n.parts/mvp/?3d-flip-book=journal-groupe-mai-2021",
        imgSrc: `${WP_CONTENT_PATH}/2021/05/JOURNAL-GROUPE_MAI2021-SLIDE-3_Page_1-scaled.jpg`,
    },
];
export const USE_KEYCLOAK = true;
