import { appsContextFactory } from "./AppsContext";

export const WIDGET_LABELS = {
    "rse-promotions": "Promotions",
    "rse-promotions-pl": "Promotions PL",
    "rse-initial-slides": "Slides initiales",
    "rse-news": "Actualités",
    "rse-news-pl": "Actualités PL",
    "rse-docs": "Documentations",
    "rse-forms": "Formulaires",
    "rse-catalog": "Catalogue",
    "rse-social": "Social",
    "rse-pack": "Pack App rse²",
    "rse-tools": "Outils Catalogue & Site",
} as const;

export const RSE_WIDGETS = [
    "rse-promotions",
    "rse-promotions-pl",
    "rse-news",
    "rse-news-pl",
    "rse-docs",
    "rse-forms",
    "rse-catalog",
    "rse-tools",
    "rse-social",
    "rse-pack",
] as const;

export const { AppsProvider, useAppsContext } = appsContextFactory("rse-");
