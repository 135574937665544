import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";

export const CAIA_LOGO_URL = `${IMAGES_SERVER}/v2/items/C@IA.png`;

export const iframeSlidesLogin = [
    "https://ipad.ia.parts/splash.php",
    "https://ipad.ia.parts/cat4classic.php",
];
export const iframeSlides = [
    "https://mvp-cloudio.ia.parts/index.php/apps/dashboard/",
    "https://mvp-cloudio.ia.parts/",
    "https://mvp-cloudio.ia.parts",
    "https://mvp-cloudio.ia.parts",
    "https://mvp-cloudio.ia.parts",
    "https://mvp-cloudio.ia.parts",
    "https://mvp-cloudio.ia.parts",
];
export const iframeSlidesCaridentifier = [
    {
        title: "LiveC@r",
        img: `${IMAGES_SERVER}/v2/carH/hub_icon.png`,
        makeURL: (vd: VehicleData): string =>
            `https://meetbox.ia.parts/${vd.Codif_Vin_PRF}`,
    },
];
